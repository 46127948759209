import { IPublicClientApplication } from "@azure/msal-browser/dist/app/IPublicClientApplication";
import { PopupRequest } from "@azure/msal-browser/dist/request/PopupRequest";

export const fetchAuthToken = async (
  instance: IPublicClientApplication,
  loginRequest: PopupRequest
) => {
  try {
    const response = await instance.acquireTokenSilent(loginRequest);
    return response.accessToken;
  } catch (error) {
    try {
      await instance.acquireTokenRedirect(loginRequest);
      const result = await instance.acquireTokenSilent(loginRequest);
      return result.accessToken;
    } catch (error) {
      console.log(error);
    }
    return "";
  }
};
