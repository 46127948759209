export function initLogger() {
  if (process.env.NODE_ENV === 'production') {
    console.log = function () {};
  }
}

export default function logger(message: any, options?: { force?: boolean, caller?: string }) {
  if (process.env.NODE_ENV === 'production' && !options?.force) {
    return;
  }

  const log: { message: any, time: string, caller?: string } = {
    message,
    time: new Date().toLocaleString(),
  };

  if(options?.caller) {
    log.caller = options.caller;
  }

  console.info(log);
}
